import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig'; // Certifique-se de que o caminho está correto

const RedirectPage = () => {
  const { id } = useParams();

  useEffect(() => {
    console.log("ID do QR Code:", id); // Log do ID
    const redirectToUrl = async () => {
      if (id) {
        try {
          const docRef = doc(db, "links", id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            console.log("Dados do documento:", data); // Log dos dados
            window.location.href = data.url; // Redireciona para a URL
          } else {
            console.log("Nenhum documento encontrado!");
            window.location.href = "/404"; // Redireciona para a página 404
          }
        } catch (error) {
          console.error("Erro ao obter documento:", error);
          window.location.href = "/404"; // Redireciona para a página 404 em caso de erro
        }
      } else {
        window.location.href = "/404"; // Redireciona se o ID não estiver presente
      }
    };

    redirectToUrl();
  }, [id]);

  return <p>Redirecionando...</p>; // Mensagem de redirecionamento
};

export default RedirectPage;
