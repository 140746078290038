import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode';
import { Box } from '@mui/material';

const QRCodeWithId = ({ value, id }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const qrCodeDataURL = await QRCode.toDataURL(value, {
          width: 300,
          margin: 2,
        });

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        // Desenha a imagem do QR code no canvas
        const qrImage = new Image();
        qrImage.src = qrCodeDataURL;
        qrImage.onload = () => {
          context.drawImage(qrImage, 0, 0);

          // Adiciona o texto do ID
          context.font = '14px Arial';
          context.fillStyle = 'black';
          context.textAlign = 'right';
          context.fillText(id, canvas.width - 3, canvas.height - 3);
        };
      } catch (error) {
        console.error("Erro ao gerar QR Code:", error);
      }
    };

    generateQRCode();
  }, [value, id]);

  return (
    <Box sx={{ border: '1px solid', borderColor: 'divider', display: 'inline-block' }}>
      <canvas
        ref={canvasRef}
        width="300"
        height="300"
      />
    </Box>
  );
};

export default QRCodeWithId;
