import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

const AdminPage = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const userDocRef = doc(db, 'users', user.email);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setIsAdmin(userDoc.data().role === 'admin');
        } else {
          await setDoc(userDocRef, { role: 'default', email: user.email });
          setIsAdmin(false);
        }
        if (isAdmin) {
          await fetchUsers();
        }
      } else {
        setIsAdmin(false);
      }
    });
    return () => unsubscribe();
  }, [isAdmin]);

  const fetchUsers = async () => {
    const usersCollection = collection(db, 'users');
    const userDocs = await getDocs(usersCollection);
    const usersList = userDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersList);
    setLoading(false);
  };

  const handleRoleChange = async (email, newRole) => {
    const userDocRef = doc(db, 'users', email);
    await updateDoc(userDocRef, { role: newRole });
    fetchUsers();
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <div>
      <h2>Painel de Administração</h2>
      {user ? (
        <>
          <p>Bem-vindo, {user.displayName}</p>
          {isAdmin && (
            <div>
              <h2>Usuários Cadastrados</h2>
              {loading ? (
                <p>Carregando usuários...</p>
              ) : (
                <ul>
                  {users.map((u) => (
                    <li key={u.id}>
                      {u.email} - {u.role}
                      <button onClick={() => handleRoleChange(u.email, 'admin')}>Tornar Admin</button>
                      <button onClick={() => handleRoleChange(u.email, 'premium')}>Tornar Premium</button>
                      <button onClick={() => handleRoleChange(u.email, 'default')}>Tornar Default</button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          <button onClick={handleLogout}>Sair</button>
        </>
      ) : (
        <p>Você precisa estar logado para acessar esta página.</p>
      )}
    </div>
  );
};

export default AdminPage;
