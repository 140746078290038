import React, { useEffect, useState, useCallback } from 'react';
import { useTheme } from '@mui/material/styles'; // Importando useTheme do MUI
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, query, where, deleteDoc } from 'firebase/firestore';
import QRCodeWithId from './QRCodeWithId'; // Importando o novo componente
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  TablePagination,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { FaHome, FaPlus, FaEdit, FaTrashAlt, FaMoon, FaSun } from 'react-icons/fa'; // Importando ícones

const LinkManager = ({ user, isPremium, isAdmin }) => {
  const theme = useTheme(); // Agora você pode usar o tema aqui

  const [links, setLinks] = useState([]);
  const [filteredLinks, setFilteredLinks] = useState([]);
  const [url, setUrl] = useState('');
  const [editingLinkId, setEditingLinkId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false); // Estado para o modal de adicionar link
  const [selectedLink, setSelectedLink] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0); // Estado para a página atual
  const [rowsPerPage, setRowsPerPage] = useState(10); // Número de linhas por página
  const db = getFirestore();
  const baseUrl = "https://myqr.com.br"; // Defina a base da URL da sua aplicação

  // Função para buscar links
  const fetchLinks = useCallback(async () => {
    if (!user) return; // Verifica se o usuário está autenticado

    let linksCollection = collection(db, 'links');
    let linkDocs;

    if (isAdmin) {
      // Administradores podem ver todos os links
      linkDocs = await getDocs(linksCollection);
    } else {
      // Usuários premium e padrão só podem ver seus próprios links
      const q = query(linksCollection, where("userEmail", "==", user.email));
      linkDocs = await getDocs(q);
    }

    const linksList = linkDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLinks(linksList);
    setFilteredLinks(linksList); // Inicializa a lista filtrada
  }, [user, isAdmin, db]);

  useEffect(() => {
    fetchLinks(); // Chama a função para buscar links ao montar o componente
  }, [fetchLinks]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = links.filter(link => 
      (link.id && link.id.toLowerCase().includes(value)) ||
      (link.userEmail && link.userEmail.toLowerCase().includes(value)) ||
      (link.url && link.url.toLowerCase().includes(value))
    );

    setFilteredLinks(filtered);
    setPage(0); // Reseta a página ao buscar
  };

  const handleAddLink = async () => {
    if (url) {
      await addDoc(collection(db, 'links'), {
        url,
        accessCount: 0, // Inicializa o contador de acessos
        userEmail: user.email,
        userId: user.uid, // Supondo que você tenha o UID do usuário
      });
      setUrl('');
      fetchLinks(); // Atualiza a lista de links
      setOpenAddDialog(false); // Fecha o modal após adicionar
    }
  };

  const handleEditLink = async () => {
    if (editingLinkId && url) {
      const linkDocRef = doc(db, 'links', editingLinkId);
      await updateDoc(linkDocRef, { url });
      setUrl('');
      setEditingLinkId(null);
      fetchLinks(); // Atualiza a lista de links
    }
  };

  const handleEditClick = (link) => {
    setUrl(link.url);
    setEditingLinkId(link.id);
    setOpenDialog(true);
    setSelectedLink(link);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedLink(null);
  };

  const handleDownloadQRCode = () => {
    const canvas = document.querySelector('canvas'); // Obtém o canvas do QRCode
    if (canvas) {
      const link = document.createElement('a');
      link.download = `${selectedLink.id}.png`; // Nome do arquivo para download
      link.href = canvas.toDataURL('image/png'); // Converte o canvas para uma URL de imagem
      link.click(); // Simula o clique para iniciar o download
    } else {
      console.error('Canvas não encontrado');
    }
  };

  const handleDeleteLink = async () => {
    if (selectedLink) {
      const linkDocRef = doc(db, 'links', selectedLink.id);
      await deleteDoc(linkDocRef);
      setOpenDialog(false);
      setSelectedLink(null);
      fetchLinks(); // Atualiza a lista de links
    }
  };

  // Função para mudar a página
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Função para mudar o número de linhas por página
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reseta a página ao mudar o número de linhas
  };

  return (
    <div style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
      <Breadcrumbs aria-label="breadcrumb" style={{ margin: '8px 0' }}>
        <Link color="inherit" href="/">
          <FaHome style={{ marginRight: '4px' }} /> Home
        </Link>
        <Link color="textPrimary" href="#" aria-current="page">
          Gerenciador de Links
        </Link>
      </Breadcrumbs>
      
      <div style={{ display: 'flex', justifyContent: 'center', margin: '16px 0' }}>
        <TextField
          label="Buscar"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Buscar por ID, Email ou URL"
          style={{ width: window.innerWidth < 600 ? '100%' : '60%' }} // Ajuste aqui
        />
      </div>
      {isPremium || isAdmin ? (
        <>
          <Button 
            variant="contained" 
            onClick={() => setOpenAddDialog(true)} 
            style={{ position: 'fixed', bottom: '40px', right: '30px', backgroundColor: 'green' }}
          >
            <FaPlus style={{ fontSize: '30px' }} />
          </Button>
        </>
      ) : (
        <p>Você precisa ser um usuário premium ou admin para criar links.</p>
      )}
      <div style={{ margin: '0 auto', maxWidth: '800px' }}>
        <TableContainer style={{ marginTop: '8px', marginBottom: '8px', overflowX: 'auto' }}>
          <Table style={{ width: '100%', tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: '8px' }}>Código de Identificação</TableCell>
                {isAdmin && <TableCell style={{ padding: '8px' }}>Criado por</TableCell>} {/* Exibe apenas para admin */}
                <TableCell style={{ padding: '8px' }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLinks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((link) => (
                <TableRow key={link.id}>
                  <TableCell style={{ padding: '8px' }}>{link.id}</TableCell>
                  {isAdmin && <TableCell style={{ padding: '8px' }}>{link.userEmail}</TableCell>} {/* Exibe apenas para admin */}
                  <TableCell style={{ padding: '8px' }}>
                    <Button variant="outlined" onClick={() => handleEditClick(link)}>
                      <FaEdit style={{ marginRight: '4px' }} /> Editar
                    </Button>
                    {isAdmin && (
                      <Button variant="outlined" color="error" onClick={() => { setSelectedLink(link); handleDeleteLink(); }}>
                        <FaTrashAlt style={{ marginRight: '4px' }} /> Excluir
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Paginação */}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]} 
          component="div"
          count={filteredLinks.length} 
          rowsPerPage={rowsPerPage} 
          page={page} 
          onPageChange={handleChangePage} 
          onRowsPerPageChange={handleChangeRowsPerPage} 
        />
      </div>

      {/* Dialog para edição de link */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Editar Link</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="URL"
            type="text"
            fullWidth
            variant="outlined"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          {selectedLink && (
            <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
              <QRCodeWithId value={`${baseUrl}/r/${selectedLink.id}`} id={selectedLink.id} />
              <Button variant="contained" onClick={handleDownloadQRCode} style={{ marginTop: '10px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                Baixar QR Code
              </Button>
              <Button 
                variant="outlined" 
                onClick={() => window.open(`${baseUrl}/r/${selectedLink.id}`, '_blank')} 
                style={{ marginTop: '10px' }}
              >
                Acessar Link
              </Button>
              {isAdmin && (
                <Button variant="outlined" color="error" onClick={handleDeleteLink} style={{ marginTop: '10px' }}>
                  Excluir Link
                </Button>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={() => { handleEditLink(); handleCloseDialog(); }}>Salvar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para adicionar novo link */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Adicionar Novo Link</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="URL"
            type="text"
            fullWidth
            variant="outlined"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Cancelar</Button>
          <Button onClick={handleAddLink}>Adicionar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LinkManager;
