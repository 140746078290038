import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { AppBar, Toolbar, Button, Avatar, CssBaseline, Container, Grid, Card, Menu, MenuItem, useMediaQuery, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FaMoon, FaSun } from 'react-icons/fa';
import AdminPage from './AdminPage';
import LinkManager from './LinkManager';
import RedirectPage from './RedirectPage';

// Configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCrPkn49bQ9VjTLTsMu2up_l151GYcO6zw",
  authDomain: "qrcode-9f641.firebaseapp.com",
  projectId: "qrcode-9f641",
  storageBucket: "qrcode-9f641.appspot.com",
  messagingSenderId: "379065507409",
  appId: "1:379065507409:web:b5a81c006d79b9c4c6e270",
  measurementId: "G-MNC3SPN9B9"
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

function App() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null); // Estado para o menu dropdown
  const isMobile = useMediaQuery('(max-width:600px)'); // Verifica se é mobile

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent 
          user={user} 
          setUser={setUser} 
          isAdmin={isAdmin} 
          setIsAdmin={setIsAdmin} 
          isPremium={isPremium} 
          setIsPremium={setIsPremium} 
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          anchorEl={anchorEl}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          isMobile={isMobile}
        />
      </Router>
    </ThemeProvider>
  );
}

const AppContent = ({ user, setUser, isAdmin, setIsAdmin, isPremium, setIsPremium, isDarkMode, setIsDarkMode, anchorEl, handleMenuClick, handleMenuClose, isMobile }) => {
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const userDocRef = doc(db, 'users', user.email);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setIsAdmin(userDoc.data().role === 'admin');
          setIsPremium(userDoc.data().role === 'premium');
        } else {
          setIsAdmin(false);
          setIsPremium(false);
        }
      } else {
        setIsAdmin(false);
        setIsPremium(false);
      }
    });
    return () => unsubscribe();
  }, [setUser, setIsAdmin, setIsPremium]);

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  };

  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
    setIsAdmin(false);
    setIsPremium(false);
  };

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img src="/myqr.png" alt="MyQR Logo" style={{ height: '40px' }} />
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            {isMobile ? (
              <>
                {user && (
                  <>
                    <Button onClick={() => setIsDarkMode(!isDarkMode)} color="inherit" style={{ marginRight: '8px' }}>
                      {isDarkMode ? <FaSun /> : <FaMoon />}
                    </Button>
                    <Avatar alt={user.displayName} src={user.photoURL} onClick={handleMenuClick} style={{ cursor: 'pointer', marginRight: '8px' }} />
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {isAdmin && <MenuItem component={Link} to="/admin" onClick={handleMenuClose}>Admin</MenuItem>}
                      {user && <MenuItem component={Link} to="/links" onClick={handleMenuClose}>Gerenciar Links</MenuItem>}
                      <MenuItem onClick={() => { handleMenuClose(); handleLogout(); }}>Sair</MenuItem>
                    </Menu>
                  </>
                )}
                {!user && <Button color="inherit" onClick={handleLogin}>Login</Button>}
              </>
            ) : (
              <>
                {isAdmin && <Button color="inherit" component={Link} to="/admin">Admin</Button>}
                {user && <Button color="inherit" component={Link} to="/links">Gerenciar Links</Button>}
                <Button onClick={() => setIsDarkMode(!isDarkMode)} color="inherit" style={{ marginRight: '8px' }}>
                  {isDarkMode ? <FaSun /> : <FaMoon />}
                </Button>
                {user ? (
                  <>
                    <Avatar alt={user.displayName} src={user.photoURL} sx={{ marginRight: 2 }} />
                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                      {user.displayName || user.email}
                    </Typography>
                    <Button color="inherit" onClick={handleLogout}>Sair</Button>
                  </>
                ) : (
                  <Button color="inherit" onClick={handleLogin}>Login</Button>
                )}
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/admin" element={isAdmin ? <AdminPage /> : <Navigate to="/" />} />
        <Route path="/links" element={user ? <LinkManager user={user} isPremium={isPremium} isAdmin={isAdmin} /> : <Navigate to="/" />} />
        <Route path="/r/:id" element={<RedirectPage />} />
        <Route path="/" element={
          <Container style={{ padding: '40px', backgroundColor: isDarkMode ? '#121212' : '#f0f4f8', borderRadius: '8px' }}>
            {/* Seção de Apresentação */}
            <div style={{ textAlign: 'center', marginBottom: '40px' }}>
              <Typography variant="h2" color={isDarkMode ? 'white' : '#333'} gutterBottom>
                Transforme sua experiência com QR Codes
              </Typography>
              <Typography variant="h5" color={isDarkMode ? '#b0bec5' : '#555'}>
                Crie, gerencie e analise seus QR Codes de forma simples e rápida!
              </Typography>
              <Button variant="contained" color="primary" onClick={handleLogin} style={{ marginTop: '20px' }}>
                Comece Agora
              </Button>
            </div>

            {/* Seção de Recursos */}
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>
                <Card elevation={3} style={{ padding: '20px', borderRadius: '12px', backgroundColor: isDarkMode ? '#2c2c2c' : '#ffffff' }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    🚀 Criação Rápida
                  </Typography>
                  <Typography variant="body1" align="center">
                    Crie QR Codes personalizados em segundos.
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Card elevation={3} style={{ padding: '20px', borderRadius: '12px', backgroundColor: isDarkMode ? '#2c2c2c' : '#ffffff' }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    📊 Análise de Dados
                  </Typography>
                  <Typography variant="body1" align="center">
                    Acompanhe o desempenho dos seus QR Codes em tempo real.
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Card elevation={3} style={{ padding: '20px', borderRadius: '12px', backgroundColor: isDarkMode ? '#2c2c2c' : '#ffffff' }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    🔒 Segurança
                  </Typography>
                  <Typography variant="body1" align="center">
                    Proteja seus dados com segurança de nível empresarial.
                  </Typography>
                </Card>
              </Grid>
            </Grid>

            {/* Seção de Testemunhos */}
            <div style={{ marginTop: '40px', textAlign: 'center' }}>
              <Typography variant="h4" color={isDarkMode ? 'white' : '#333'} gutterBottom>
                O que nossos usuários dizem
              </Typography>
              <Typography variant="body1" color={isDarkMode ? '#b0bec5' : '#555'}>
                "O MyQR transformou a maneira como gerencio meus códigos. Simples e eficaz!" - Usuário Satisfeito
              </Typography>
            </div>

            {/* Seção de Empresas que Utilizam */}
            <div style={{ marginTop: '40px', textAlign: 'center' }}>
              <Typography variant="h4" color={isDarkMode ? 'white' : '#333'} gutterBottom>
                Empresas que utilizam o MyQR
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <img src="empresa1.png" alt="Empresa 1" style={{ width: '100px', margin: '10px' }} />
                </Grid>
                <Grid item>
                  <img src="empresa2.png" alt="Empresa 2" style={{ width: '100px', margin: '10px' }} />
                </Grid>
                <Grid item>
                  <img src="empresa3.png" alt="Empresa 3" style={{ width: '100px', margin: '10px' }} />
                </Grid>
              </Grid>
            </div>
          </Container>
        } />
      </Routes>
    </div>
  );
}

export default App;
